import React, { useState } from 'react';
import { CHART_OPTIONS, CHART_OPTIONS_J, findColor, RootServerSystemMetricChartProps, RootServerType, SystemMetricChartOptions } from '../custom_types';
import { Line } from "react-chartjs-2";
import { Settings } from 'luxon';

import 'chartjs-adapter-luxon';
import { isJRootServer } from '../core/DomainUtils';
import Fetcher from '../core/Fetcher';

/*@ts-ignore*/
Settings.defaultZoneName = 'utc';

export const RootServerSystemMetric = () => {
    const [chart, setChart] = useState(CHART_OPTIONS[0].type);

    const onChartChange = (event: any) => {
        const value = event.target.value;
        setChart(value);
    }
    const chart_options_to_use = isJRootServer() ? CHART_OPTIONS_J : CHART_OPTIONS;
    return <div className="container pt-4">


        <div className="row">
            <div className="col-12 col-md-10">
                <RootServerSystemMetricChart type={chart} />

            </div>
            <div className="col-12 col-md-2 ">

                <div className="mt-4">
                    {chart_options_to_use.map((value, index) => {
                        return <div className="form-check form-check-inline" key={index}>
                            <input className="form-check-input" type="radio"
                                value={value.type} name="chart"
                                checked={chart === value.type}
                                onChange={onChartChange} />
                            <label className="form-check-label" >
                                {value.title}
                            </label>
                        </div>
                    })}
                </div>


            </div>
        </div>
        <div className="mt-4 text-md-center ">

            <span className=" text-muted daily-files ">
                The daily <a href="/rssac-metrics/raw">raw YAML files
                </a> are also available.

            </span>

        </div>
    </div >
}

const getChartOptions = (type: string) => {

    const found = CHART_OPTIONS.find(v => type === v.type);
    if (!found) {
        return CHART_OPTIONS[0];
    }
    return found;
}



const RootServerSystemMetricChart = ({ type }: RootServerSystemMetricChartProps) => {


    /*chart title and append A or J root to it*/
    const chartOptions: SystemMetricChartOptions = getChartOptions(type);
    let title = chartOptions.chartTitle;
    if (isJRootServer()) {
        title = RootServerType.J + "-root " + title;
    } else {
        title = RootServerType.A + "-root " + title;
    }

    //stacked chart for volumne and error response types
    const stacked = (type === "volume" || type === "rcodes");
    const options = {
        //mobile friendly https://stackoverflow.com/a/48523682
        responsive: true,
        maintainAspectRatio: false,
        //https://stackoverflow.com/a/49094480/206351
        tooltips: {
            mode: 'index',
            intersect: false
        },
        hover: {
            mode: 'index',
            intersect: false
        },

        //https://www.chartjs.org/docs/latest/configuration/interactions.html
        interaction: {
            mode: 'nearest'
        },
        plugins: {
            legend: {
                display: true,
                //for stacked values, we want to show labels in reverse order
                reverse: stacked,
                position: "top"
            },
            //https://www.chartjs.org/docs/master/configuration/title.html
            title: {
                display: true,
                font: {
                    size: 18
                },
                //position:"bottom",
                text: title
            }
        },
        // parsing: false,
        //pointBorderWidth: 0,
        pointHoverRadius: 8,
        pointHoverBorderWidth: 0,
        lineWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        scales: {
            x: {
                type: 'time',
                adapters: {
                    date: {
                        zone: 'utc'
                    }
                },
                time: {
                    //taken from https://moment.github.io/luxon/#/formatting?id=table-of-tokens
                    tooltipFormat: "EEE, dd MMM yyyy HH:mm:ss a  ZZZZ",
                    //show months on x axis
                    //https://www.chartjs.org/docs/latest/axes/cartesian/time.html#time-units
                    unit: 'month',
                }
            },

            y: {
                stacked: stacked,
                beginAtZero: true
            }
        }
    } as const; // see https://www.typescriptlang.org/docs/handbook/2/everyday-types.html#literal-inference

    return (<Fetcher endpoint={"/api/v1/rssac-metrics/" + type}>
        {(data: any) => {
            if (data && data.datasets) {
                for (let i = 0; i < data.datasets.length; ++i) {
                    let dataset = data.datasets[i];
                    /*set border colors*/
                    const color = findColor(dataset.metric_type, i);
                    //const color2 = colorScheme[i+6];
                    dataset["borderColor"] = color;
                    dataset["pointBackgroundColor"] = color;
                    dataset["pointBorderColor"] = color;
                    dataset["pointHoverBackgroundColor"] = color;
                    dataset["pointHoverBorderColor"] = color;
                    if (stacked) {
                        dataset["fill"] = true

                    }
                    dataset["backgroundColor"] = color
                }
            }
            return <div className="wrapper">
                <Line data={data} options={options} />
            </div>
        }
        }
    </Fetcher >
    );



}