import React from 'react';


export const Footer = () => {

    return <footer>
        <div className="footer-links">
            <div className="footer-link-container">
                <a href="https://www.verisign.com/en_US/legal-notices/index.xhtml" rel="noreferrer"  target="_blank">
                    LEGAL NOTICES
                </a>
                <a href="https://www.verisign.com/privacy-center/index.xhtml?loc=en_US" rel="noreferrer"  target="_blank">
                    PRIVACY
                </a>

            </div>
        </div>
        <div className="footer-copyright">
            <p>©&nbsp;{(new Date().getFullYear())}&nbsp;VeriSign, Inc. All rights reserved.</p><p>VERISIGN, the VERISIGN logo, and other trademarks, service marks, and designs are registered or unregistered trademarks of VeriSign, Inc. and its subsidiaries in the United States and in other countries.</p>
            <p>All other trademarks are property of their respective owners.</p>
        </div>
    </footer>
}