
import React from 'react';

export const Spinner = () => {
    return <div className="d-flex justify-content-center">
        
        <div className="spinner-border text-primary large-spinner " role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>


}