import { useState, useEffect } from 'react';
import { executeGet, POST, executePost } from './Api';

function useFetcher(endpoint, method) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    async function loadData() {
        try {
            setLoading(true);
            const actionData = method === POST ? await executePost(endpoint, {}) : await executeGet(endpoint);
            const json = await actionData.json();
            setData(json);
        } catch (e) {
            //console.log(e);
            setError(e);
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        loadData();
    }, [endpoint]) // eslint-disable-line react-hooks/exhaustive-deps

    return [data, loading, error];
}

export default useFetcher;