export const DATE_FORMAT = "YYYY-MM-DD"
export const GET = "GET"
export const POST = "POST"


function handleErrors(response: any) {
    if (!response.ok) {
        // Swal.fire({
        //     icon: 'error',
        //     title: 'Oops...',
        //     text: response.status === 403 ? 'Access Denied. ' : 'Sorry, an error ocurred - ' + response.statusText
        // })
        throw Error(response.statusText);
    }
    return response;
}

/**
 * calls backend and handles errors gracefully
 * 
 * 
 * @param endpoint - endpoint
 * 
 */
export function executeGet(endpoint: string) {

    return fetch(endpoint, {
        method: GET,
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },

    }).then(handleErrors);
}

export function executePost(endpoint: string, data: any) {
    return fetch(endpoint, {
        method: POST,
        headers: {
            //'X-XSRF-TOKEN': getCSRFToken(),
            "Content-Type": "application/json; charset=utf-8",
            // "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify(data),
    }).then(handleErrors)
}



