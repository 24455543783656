/**
 * common component to fetch data from backend
 * https://medium.com/front-end-weekly/data-fetcher-component-using-hooks-and-render-props-aacf3162dfc2
 */
import React from 'react';
import { Spinner } from './Spinner';
import { Error } from './Error';
import useFetcher from './useFetcher';
import { GET } from './Api';
const Fetcher = ({ endpoint, method = GET, children }) => {
  const [data, loading, error] = useFetcher(endpoint, method);

  if (loading) return <Spinner />;
  if (error) return <Error error={error} />
  if (!data) return null;
  return <div>
    {children(data)}
  </div>;
};
export default Fetcher;