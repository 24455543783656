
import { RootServerType } from "../custom_types";

/**
 * returns domain name. for ex: a.root-servers.org or j.root-servers.org
 */
export const extractDomainName = function () {
    return isJRootServer() ? "j.root-servers.org" : "a.root-servers.org"
}
/*
 * extracts root server type from url
 *
 * for ex: https://j.root-servers.org/, https://a.root-servers.org/, eq-int-a.rssacweb.vrsn.com, eq-int-j.rssacweb.vrsn.com
 * @return - root server type from url
 */
const extractRootServerType = function (): RootServerType {
    const href = window.location ? window.location.href : null;

    if (href && (href.indexOf("j.root") > 0
        || href.indexOf("j.rssacweb") > 0 || href.indexOf("-j.") > 0
    )) {
        return RootServerType.J;
    } else if (href && (href.indexOf("a.root") > 0
        || href.indexOf("a.rssacweb") > 0 || href.indexOf("-a.") > 0
    )) {
        return RootServerType.A;
    } else {
        /*by default return A root server. useful during testing in dev/qa envs*/
        return RootServerType.A
    }
};

export const isJRootServer = () => {
    const rootServerType: RootServerType = extractRootServerType();
    return RootServerType.J === rootServerType
}