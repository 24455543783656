import React from 'react';
import { Footer } from './components/nav/Footer';
import { Header } from './components/nav/Header';

import { RootServerSystemMetric } from './components/RootServerSystemMetricChart';
import {
  BrowserRouter as Router,
  Switch, Route
} from "react-router-dom";
import { Home } from './components/Home';
function App() {
  return (
    <Router>


      <div>
        <Header />
        <div className=" mt-2 min-vh-100">

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/metrics" component={RootServerSystemMetric} />
            {/* <Route path="/rssac-metrics/raw" component={RawData} />
             */}
            {/* <Route path="/rssac-metrics/raw/:id?" component={RawData} />
             */}
            <Route path="*" component={Home} />
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
