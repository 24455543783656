import React from 'react';
import ReactLogo from '../../logo.svg';
import { Link, useLocation } from "react-router-dom";
import { extractDomainName } from '../../core/DomainUtils';



export const Header = () => {
    const location = useLocation();

    return <div>
        <div className="top">
            <div className="one_col">
                <div className="logo">
                    <img src={ReactLogo} alt="logo" />
                    <div className="clear"></div>
                </div>
            </div>
        </div>
        <div className="hero">
            <div className="hero_image">
                <div className="hero_overlay">
                    <h1>{extractDomainName()}</h1>
                </div>
            </div>
        </div>


        <div className="navtabs">
            <ul>
                <li className={!location.pathname || location.pathname === "/" ? "selected" : ""}>
                    <Link to={"/"}><span>HOME</span></Link>
                </li>
                <li className={location.pathname === "/metrics" ? "selected two_lines" : ""}>
                    <Link to={"/metrics"} className="two_lines"
                    ><span>ROOT SERVER SYSTEM METRICS</span>
                    </Link>
                </li>
                <li className="omega">
                    <a href="/rssac-metrics/raw">
                        RAW DATA
                    </a>
                </li>

            </ul>
        </div>
    </div>
}