export interface RootServerSystemMetricChartProps {
    type: string;
}
export interface SystemMetricChartOptions {
    type: string;
    title: string;
    yAxisTitle: string;
    chartTitle: string;
}

export enum RootServerType {
    A = "A", J = "J"
}

export const LOAD_TIME_CHART_OPTIONS: SystemMetricChartOptions =
{
    type: "load-time",
    title: "Distribution Latency",
    yAxisTitle: "Load Time (Seconds)",
    chartTitle: "Load Time (Seconds)"

}

export const ZONE_SIZE_CHART_OPTIONS: SystemMetricChartOptions =
{
    type: "zonesize",
    title: "Zone Size",
    yAxisTitle: "Size of the Root Zone (Kilobytes)",
    chartTitle: "Size of the Root Zone (Kilobytes)"

}

export const QUERY_SOURCE_CHART_OPTIONS: SystemMetricChartOptions =
{
    type: "sources",
    title: "Sources",
    yAxisTitle: "Query Sources (Millions)",
    chartTitle: "Query Sources (Millions)"

}
export const QUERY_VOLUME_CHART_OPTIONS: SystemMetricChartOptions =
{
    type: "volume",
    title: "Query Volume",
    yAxisTitle: "Query Volume (Millions/Day)",
    chartTitle: "Query Volume (Millions/Day)"

}
export const RCODES_CHART_OPTIONS: SystemMetricChartOptions =
{
    type: "rcodes",
    title: "Response Codes",
    yAxisTitle: "Response Codes (Millions/Day)",
    chartTitle: "Response Codes (Millions/Day)"

}
export const UDP_QUERY_SIZES_OPTIONS: SystemMetricChartOptions =
{
    type: "uqsize",
    title: "UDP Query Sizes",
    yAxisTitle: "UDP Query Message Size Percentiles (Bytes)",
    chartTitle: "UDP Query Message Size Percentiles (Bytes)"

}
export const UDP_REPLY_SIZES_OPTIONS: SystemMetricChartOptions =
{
    type: "ursize",
    title: "UDP Reply Sizes",
    yAxisTitle: "UDP Reply Message Size Percentiles (Bytes)",
    chartTitle: "UDP Reply Message Size Percentiles (Bytes)"

}
export const TCP_QUERY_SIZES_OPTIONS: SystemMetricChartOptions =
{
    type: "tqsize",
    title: "TCP Query Sizes",
    yAxisTitle: "TCP Query Message Size Percentiles (Bytes)",
    chartTitle: "TCP Query Message Size Percentiles (Bytes)"

}
export const TCP_REPLY_SIZES_OPTIONS: SystemMetricChartOptions =
{
    type: "trsize",
    title: "TCP Reply Sizes",
    yAxisTitle: "TCP Reply Message Size Percentiles (Bytes)",
    chartTitle: "TCP Reply Message Size Percentiles (Bytes)"

}

export const CHART_OPTIONS: SystemMetricChartOptions[] = [
    QUERY_VOLUME_CHART_OPTIONS,
    QUERY_SOURCE_CHART_OPTIONS,
    RCODES_CHART_OPTIONS,
    LOAD_TIME_CHART_OPTIONS,
    ZONE_SIZE_CHART_OPTIONS,

    UDP_QUERY_SIZES_OPTIONS,
    UDP_REPLY_SIZES_OPTIONS,
    TCP_QUERY_SIZES_OPTIONS,
    TCP_REPLY_SIZES_OPTIONS,

]
//don't show zonesize  for j
export const CHART_OPTIONS_J: SystemMetricChartOptions[] = [
    QUERY_VOLUME_CHART_OPTIONS,
    QUERY_SOURCE_CHART_OPTIONS,
    RCODES_CHART_OPTIONS,
    LOAD_TIME_CHART_OPTIONS,

    UDP_QUERY_SIZES_OPTIONS,
    UDP_REPLY_SIZES_OPTIONS,
    TCP_QUERY_SIZES_OPTIONS,
    TCP_REPLY_SIZES_OPTIONS,

]

const COLOR_1 = "#3860BE"
const COLOR_2 = "#00A1DE"
const COLOR_3 = "#5B8F22"
const COLOR_4 = "#E74C3C"

const COLOR_5 = "#A71D0F"
const COLOR_6 = "#9DABE2"
const COLOR_7 = "#5F09BA"



export const COLORS_BY_TYPE = {
    IPV6_TCP_QUERIES: COLOR_1,
    IPV6_UDP_QUERIES: COLOR_2,
    IPV4_TCP_QUERIES: COLOR_3,
    IPV4_UDP_QUERIES: COLOR_4,

    IPV6_64_ADDRESSES: COLOR_1,
    IPV6_ADDRESSES: COLOR_2,
    IPV4_ADDRESSES: COLOR_3,

    OTHER: COLOR_1,
    REFUSED: COLOR_2,
    NOTIMP: COLOR_3,
    NXDOMAIN: COLOR_4,
    SERVFAIL: COLOR_5,
    FORMERR: COLOR_6,
    NOERROR: COLOR_7,

    LOAD_TIME_SECONDS: COLOR_1,
    SIZE_OF_ROOT_ZONE: COLOR_1,

    _95TH_PERCENTILE: COLOR_1,
    _75TH_PERCENTILE: COLOR_2,
    _50TH_PERCENTILE: COLOR_3,
    _25TH_PERCENTILE: COLOR_4,
    _5TH_PERCENTILE: COLOR_5,

}
const COLORS = [
    COLOR_1, COLOR_2, COLOR_3, COLOR_4,
    COLOR_5, COLOR_6, COLOR_7
];

export function findColor(metric_type: string, index: number) {
    /*@ts-ignore*/
    const colorToUse = COLORS_BY_TYPE[metric_type];
    return colorToUse ? colorToUse : COLORS[index % COLORS.length];
}

