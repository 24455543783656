import React from 'react';
import { Link } from 'react-router-dom';
import { isJRootServer } from '../core/DomainUtils';

export const AHome = () => {
    return <div className="container text-muted pb-4 pt-4">
        <p className=""><a href="http://www.verisign.com"
            target="_blank" rel="noreferrer">Verisign</a> operates
            <span className="fw-bold"> a.root-servers.net</span>, one of the thirteen logical Internet Root name servers.
            Verisign cooperates with the eleven other <a href="http://www.root-servers.org" target="_blank" rel="noreferrer">Root Server Operators</a> to provide authoritative data for the DNS Root Zone.
        </p>

        <p>A-root receives DNS queries over
            IPv4 at <span className="fw-bold">198.41.0.4</span> and
            over IPv6 at <span className="fw-bold">2001:503:ba3e::2:30</span>.
        </p>
        <p>A-root uses IP Anycast to provide service from a
            number of locations throughout the world, which may change from time to time.
        </p>
        <p>
        Verisign's&nbsp;
        <a href="/aroot-rssac001v2-expectations.pdf">statement on service expectations for A-root&nbsp;</a>is available.
        </p>
        <p>Interactive  <Link to={"/metrics"}>Root Server System Metrics
        </Link> for A-root are updated daily in accordance
            with the <span className="fw-bold">RSSAC
                Recommendation on Measurements of the Root Server System
            </span>. The <a href="/rssac-metrics/raw">raw data</a> is also available.
        </p>
        <h3 className="mt-4 mb-3 text-md-center">History</h3>

        <p>Prior to the development of the Domain Name System, domain names and IP
            addresses were
            allocated by the "Network Information Center" (NIC) at the Stanford Research Institute (SRI).
            When DNS was initially proposed in the early 1980's, SRI operated one of three initial root
            name servers.There were only four root name servers until late 1987.
        </p>

        <p>In 1991, the Defense Information Systems Agency awarded the NIC contract to Government Systems,
            Inc.(GSI), which in turn outsourced the contract to Network Solutions, Inc.(NSI).
        </p>

        <p>In 1993, NSI added <span className="fw-bold">ns.internic.net</span> as a root
            name server, with IP address 198.41.0.4.That same year its network connection was upgraded
            from 56K to T1 (1.5 Mbps).
        </p>

        <p>By late 1993 the number of root name servers had grown to an extent that the size of
            a "root hints" response was approaching the limit of 512 bytes.A plan was formed to
            rename all root servers under the <span className="fw-bold">root-servers.net</span> domain
            and <span className="fw-bold">ns.internic.net</span> was renamed to <span className="fw-bold">a.root-servers.net</span> in September 1995.
        </p>

        <p>In 2000, Network Solutions, Inc.was acquired by Verisign.</p>

        <p>In 2008, A-root became a distributed service utilizing IP anycast.</p>

        <p>In 2008, the IPv6 address 2001:503:ba3e::2:30 was added for A-root.</p>

    </div>
}
export const JHome = () => {
    return <div className="container text-muted pb-4 pt-4">
        <p className=""><a href="http://www.verisign.com" target="_blank" rel="noreferrer">Verisign</a> operates
            <span className="fw-bold"> j.root-servers.net</span>, one of the thirteen logical Internet Root name servers.
            Verisign cooperates with the eleven other <a href="http://www.root-servers.org" target="_blank" rel="noreferrer">Root Server Operators</a> to provide authoritative data for the DNS Root Zone.
        </p>

        <p>J-root receives DNS queries over
            IPv4 at <span className="fw-bold">192.58.128.30</span> and
            over IPv6 at <span className="fw-bold">2001:503:c27::2:30</span>.
        </p>
        <p>J-root uses IP Anycast to provide service from a
            number of locations throughout the world, which may change from time to time.
        </p>
         <p>Verisign's&nbsp;<a href="/jroot-rssac001v2-expectations.pdf">statement on service expectations for J-root&nbsp;</a>is available.
</p>
        <p>Interactive  <Link to={"/metrics"}>Root Server System Metrics
        </Link> for J-root are updated daily in accordance
            with the <span className="fw-bold">RSSAC
                Recommendation on Measurements of the Root Server System
            </span>. The <a href="/rssac-metrics/raw">raw data</a> is also available.
        </p>

        <p>Are you interested in hosting a J-root instance at your
            exchange point or on your own IP network? For more information
            about this offering, including minimum technical requirements
            and access to our automated request system,
            please visit <a href="http://www.verisign.com/rirs"
                target="_blank" rel="noreferrer">http://www.verisign.com/rirs</a>.
        </p>

        <h3 className="mt-4 mb-3 text-md-center">History</h3>

        <p>
            In 1997, <span className="fw-bold">j.root-servers.net</span> was
            added as the 10th root name server. Operated by Network Solutions, it was initially co-located
            with A-root and used the IP address 198.41.0.10.
        </p>

        <p>In 2000, Network Solutions, Inc.was acquired by Verisign.</p>

        <p>
            In 2002, J-root was renumbered to 192.58.128.30, thus allowing it to be anycasted.

        </p>
        <p>In 2008, the IPv6 address 2001:503:c27::2:30 was added for J-root.</p>

    </div>
}
export const Home = () => {
    if (isJRootServer()) {
        return <JHome />
    } else {
        return <AHome />
    }
}